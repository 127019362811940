<template>
  <div>
    <v-dialog v-model="show_details" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Queue Detail</v-card-title>
        <v-card-text>
          <pre style="padding: 20px; background: #120f12; color: #fff; border-radius: 10px">
          {{ queue.properties }}
         </pre
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" small text @click="show_details = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show_exception" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Exception</v-card-title>

        <v-card-text>
          <pre style="padding: 20px; background: #120f12; color: #fff; border-radius: 10px; max-height: 85vh; overflow:scroll">
            {{ queue.exception }}
          <!-- {{ queue.properties }} -->
         </pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" small text @click="show_exception = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <h2 class="text-h5 mb-4">Failed Queues</h2>

      <v-card class="mb-4" style="padding: 10px">
        <v-col cols="12" md="3">
          <v-select
            @change="fetchQueues"
            v-model="filters.queue_type"
            :items="['All', 'Feed Upload', 'Product Upload', 'Reverse Sync']"
            label="Queue Type"
          ></v-select>
        </v-col>
      </v-card>
      <v-data-table :headers="headers" :items="queues" :items-per-page="15" class="elevation-1">
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-if="!items.length">
              <td colspan="7" class="text-center" style="padding: 20px 0px">
                <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
                <p style="font-size: 25px">No Data Found!!</p>
              </td>
            </tr>
            <tr v-else v-for="item in items" :key="item.id" :set="(payload = JSON.parse(item.payload))">
              <td>{{ payload.displayName }}</td>
              <td>
                {{ item.failed_at }}
              </td>
              <td>
                <v-btn @click="showException(item)" small color="red darken-1" dark class="table-action--button">
                  View Exception
                </v-btn>
              </td>
              <td>
                <v-btn @click="showDetails(item)" fab small color=" darken-1" class="table-action--button">
                  <v-icon small dark>{{ icons.mdiEye }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mdiEye } from '@mdi/js'
export default {
  data() {
    return {
      loading_data: false,
      filters: {
        queue_type: 'All',
      },
      headers: [
        {
          text: 'Queue Type',
          value: 'queue',
        },
        { text: 'Failed Date', value: 'failed_at' },
        { text: 'Exception' },
        { text: 'Actions', value: 'actions' },
      ],
      queues: [],

      icons: {
        mdiEye: mdiEye,
      },

      show_details: false,
      show_exception: false,
      queue: {},
    }
  },
  mounted() {
    this.fetchQueues()
  },
  methods: {
    async fetchQueues() {
      this.loading_data = true
      try {
        let response = await axios.get('/admin/fetch-failed-queues', { params: this.filters })
        if (response.data.success) {
          this.queues = response.data.queues
        }
      } catch (e) {
      } finally {
        this.loading_data = false
      }
    },

    showDetails(item) {
      this.queue = item
      this.show_details = true
    },

    showException(item) {
      this.queue = item
      this.show_exception = true
    },
  },
}
</script>